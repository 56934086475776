<template>
  <div class="vest">
    <div class="header">
      <img src="@/assets/img/hi-icon.png" />
      <h1 class="title">Selamat Datang kembali!</h1>
    </div>
    <div class="content">
      <div class="time-header">
        <div class="date-time" @click="visible = true">
          <p>{{ currentMonth }}</p>
          <img src="@/assets/img/vest-arrow-down.png" />
        </div>
        <p class="location" @click="getLocation">{{ city }}</p>
      </div>
      <div class="total">
        <div>
          <div class="gross">
            <p class="label">Total</p>
            <p class="amount">{{ (info.totalExpenditure || 0) + (info.totalIncome || 0) }}</p>
          </div>
          <div class="month">
            <div>
              <p class="label">Biaya bulan ini</p>
              <p class="amount">{{ info.totalExpenditure }}</p>
            </div>
            <div>
              <p class="label">Penghasilan bulan ini</p>
              <p class="amount">{{ info.totalIncome }}</p>
            </div>
          </div>
        </div>
      </div>
      <h2 class="bill-title">Semua tagihan</h2>
      <div class="bill-list" v-if="info.map && info.map.length">
        <div v-for="item in info.map" :key="item.dateName">
          <div class="title">
            <div>
              <p class="which-day" v-if="isToday(item.date)">Hari ini</p>
              <p class="day">{{ item.dateName }}</p>
            </div>
            <div>
              <p class="consume">pengeluaran<span>{{ item.totalExpenditureday }}</span></p>
              <p class="consume">penghasilan<span>{{ item.totalIncomeday }}</span></p>
            </div>
          </div>
          <div class="item-list">
            <div v-for="ele in item.billDetails" :key="ele.billId" @click="goDetail(ele)">
              <img :src="ele.categoryImages" />
              <div class="desc">
                <div class="left-block">
                  <div class="useage">
                    <p class="category">{{ ele.billName }}</p>
                    <p class="time">{{ ele.billTime | formatTime }}</p>
                  </div>
                  <p class="address">{{ ele.recordLocation }}</p>
                </div>
                <p :class="['amount', { income: ele.billType === 2 }]">{{ ele.billType === 2 ? '+' : '-' }}{{ ele.billAmount }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-else>
        <img class="empty-icon" src="@/assets/img/empty-icon.png" />
        <p class="empty">Tidak ada data</p>
      </template>
    </div>
    <van-popup v-model="visible" round position="bottom">
      <van-datetime-picker v-model="currentDate" type="year-month" title="Silakan pilih tanggal" :formatter="formatter" confirm-button-text="Tentu" cancel-button-text="Membatalkan" @cancel="onCloseDialog()" @confirm="confirm" />
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import { Popup, Toast, DatetimePicker } from 'vant'
import request from '@/utils/request'
import dialogVisible from '@/mixins/dialogVisible'
import webviewBack from '@/mixins/webviewBack'

Vue.use(Popup)
Vue.use(Toast)
Vue.use(DatetimePicker)

function getTimeStr (currentDate) {
  return currentDate.valueOf().toString().substr(0, 10)
}

function getCurrentMonth (currentDate) {
  return `${currentDate.getFullYear()}Tahun${currentDate.getMonth() + 1}Bulan`
}

const currentDate = new Date()
const currentMonth = getCurrentMonth(currentDate)

export default {
  name: 'Vest',
  mixins: [webviewBack, dialogVisible],
  data () {
    return {
      currentDate,
      currentMonth,
      info: {},
      city: 'lokasi'
    }
  },
  created () {
    // currentDate.setMonth(9)
    this.getBill(currentDate)
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.getBill(currentDate)
      }
    }, false)
    this.reportLocation()
  },
  filters: {
    formatTime (value) {
      const date = new Date(value * 1000)
      let hour = date.getHours()
      let minutes = date.getMinutes()
      if (hour >= 0 && hour <= 9) {
        hour = '0' + hour
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = '0' + minutes
      }
      return hour + ':' + minutes
    }
  },
  methods: {
    isToday (date) {
      const targetDate = new Date(date)
      const currentDate = new Date()
      const isSameYear = targetDate.getFullYear() === currentDate.getFullYear()
      const isSameMonth = targetDate.getMonth() === currentDate.getMonth()
      const isSameDay = targetDate.getDate() && currentDate.getDate()
      if (isSameYear && isSameMonth && isSameDay) {
        return true
      } else {
        return false
      }
    },
    formatter (type, val) {
      if (type === 'year') {
        return `${val}Tahun`
      } else if (type === 'month') {
        return `${val}Bulan`
      }
      return val
    },
    getBill (currentDate) {
      const postData = {
        billTime: getTimeStr(currentDate)
      }
      return request.post('bookkeepingHomepage', postData).then(res => {
        this.info = res
      })
    },
    confirm (value) {
      console.log(value)
      this.getBill(value).then(() => {
        this.currentMonth = getCurrentMonth(value)
      })
      this.onCloseDialog()
    },
    goDetail (item) {
      const { billId, billType, billAmount, billTimes, categoryImages, recordLocation, remarks, billName } = item
      this.$router.push({
        path: '/vestDetail',
        query: {
          billId,
          billType,
          billAmount,
          billTimes,
          categoryImages,
          recordLocation,
          remarks,
          billName
        }
      })
    },
    getLocation () {
      window.webkit.messageHandlers.getBuKuLocation.postMessage('')
    },
    reportLocation () {
      // 客户端调用方法
      window.respoToKJSBnseLocation = (data = {}) => {
        const { ciBKty = '' } = data
        this.city = ciBKty
      }
    }
  }
}
</script>

<style scoped lang="less">
.vest {
  min-height: 100vh;
  background-color: #F8F8F8;
}

.header {
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-left: 25px;

  img {
    width: 45px;
    height: 30px;
  }

  .title {
    line-height: 34px;
    margin-left: 20px;
    font-size: 36px;
    color: #333;
  }
}

.content {
  padding: 57px 30px 40px;
}

.date-time {
  display: flex;
  align-items: center;

  p {
    font-size: 32px;
    color: #333;
  }

  img {
    width: 22px;
    height: 13px;
    margin-left: 10px;
  }
}

.time-header {
  display: flex;
  justify-content: space-between;

  .location {
    font-size: 30px;
    color: #333;
  }
}

.total {
  display: flex;
  justify-content: space-between;
  height: 263px;
  padding: 37px 16px 25px 30px;
  border-radius: 20px;
  box-sizing: border-box;
  margin-top: 28px;

  .gross {
    .amount {
      font-size: 60px;
    }
  }

  .month {
    display: flex;
    margin-top: 20px;

    & > div:last-of-type {
      margin-left: 28px;
    }
  }

  p {
    line-height: 1;
    color: #333;
  }

  .label {
    font-size: 28px;
    font-weight: 500;
  }

  .amount {
    font-size: 32px;
    margin-top: 5px;
  }

  img {
    width: 259px;
    height: 206px;
    margin-top: 7px;
  }
}

.bill-title {
  display: inline-block;
  position: relative;
  margin-top: 30px;
  font-size: 36px;
  color: #333;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -10px;
    width: 50%;
    height: 6px;
    border-radius: 3px;
    transform: translateX(-50%);
    background-color: #418DFD;
  }
}

.bill-list {
  margin-top: 30px;

  & > div {
    padding: 26px 30px 9px;
    border-radius: 20px;
    box-sizing: border-box;
    background-color: #FFF;

    &:nth-child(n+2) {
      margin-top: 20px;
    }

    .title {
      display: flex;
      align-items: flex-end;

      & > div:last-child {
        margin-left: 51px;
      }

      .which-day {
        font-size: 36px;
        font-weight: bold;
        color: #333;
        margin-right: 6px;
      }

      .day {
        flex: 1;
        font-size: 28px;
        font-weight: bold;
        color: #666;
      }

      .consume {
        font-size: 28px;
        font-weight: 500;
        color: #666;

        span {
          margin-left: 15px;
        }
      }
    }
  }

  .item-list {
    margin-top: 20px;

    & > div {
      display: flex;
      align-items: center;

      &:nth-child(n+2) {
        .desc {
          border-top: 1px solid #EEE;
        }
      }

      img {
        width: 80px;
        height: 80px;
        border-radius: 20px;
      }

      .desc {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-left: 20px;
      }

      .left-block {
        flex: 1;
      }

      .useage {
        display: flex;
        align-items: baseline;

        p {
          font-weight: bold;
          color: #333;
        }
      }

      .category {
        font-size: 32px;
      }

      .time {
        margin-left: 12px;
        font-size: 24px;
      }

      .address {
        margin-top: 4px;
        font-size: 24px;
        font-weight: 500;
        color: #999;
      }

      .amount {
        font-size: 36px;
        font-weight: bold;
        color: #333;

        &.income {
          color: #FC8741;
        }
      }
    }
  }
}

.empty-icon {
  width: 408px;
  height: 331px;
  margin: 238px auto 0;
}

.empty {
  font-size: 28px;
  font-weight: 500;
  color: #333;
  text-align: center;
}
</style>
